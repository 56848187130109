import React from "react";
import { css } from "@emotion/react";
import { Div } from "@components/Markup";
import PasswordScreen from "@components/PasswordScreen";
import Footer from "@components/Footer";

const SectionIntro = ({ children }) => {
  return (
    <Div
      css={css`
        background-color: black;
        color: white;
        position: relative;
      `}
    >
      {children}
      <Footer />
      <PasswordScreen />
    </Div>
  );
};

export default SectionIntro;
