import React, { useRef, useEffect, useContext } from "react";
import { LoadContext } from "@providers/LoadProvider";
import { gsap, ScrollTrigger } from "gsap/all";
import { ScrollContext } from "@providers/ScrollProvider";
import { PassProtectionContext } from "@providers/PassProtectionProvider";
import { Wrapper, ContainerDiv, Image, ImageWrapper } from "./galleryStyles";
gsap.registerPlugin(ScrollTrigger);

const CONTAINER_ID = "smooth-scroll";

const Gallery = ({ items }) => {
  const tlRef = useRef(null);
  const containerRef = useRef(null);
  const wrapperRef = useRef(null);

  const { scrollContainer } = useContext(ScrollContext);
  const { images } = useContext(LoadContext);
  const { passwordIsTrue } = useContext(PassProtectionContext);
  const myItems = items.map(item => item.fluid);

  // useEffect(() => {
  //   return () => {
  //     if (tlRef.current) {
  //       console.log("oh shit");
  //       tlRef.current.kill();
  //     }
  //   };
  // }, []);

  useEffect(() => {
    if (images) {
      initTl();
    }
  }, [images]);

  const initTl = () => {
    console.log("init gallery"); //shit
    if (tlRef.current) {
      tlRef.current.clear();
      containerRef.current && gsap.set(containerRef.current, { x: 0 });
    }

    let scrollTriggerOptions = {
      trigger: wrapperRef.current,
      start: "top top",
      end: `+=${containerRef.current.offsetWidth}px`,
      scrub: true,
      pin: wrapperRef.current,
      onUpdate: self => {
        console.log("self", self);
      },
    };

    //scrollTriggerOptions.scroller = document.querySelector(`#${CONTAINER_ID}`);

    console.log(scrollTriggerOptions);

    const offset = `-=${
      containerRef.current?.offsetWidth - window.innerWidth
    }px`;

    console.log("my offset", offset);

    tlRef.current = gsap
      .timeline({
        scrollTrigger: scrollTriggerOptions,
      })
      .to(containerRef.current, {
        x: offset,
        ease: "power2.inOut",
      });
  };

  return (
    <Wrapper ref={wrapperRef}>
      <ContainerDiv
        ref={containerRef}
        firstImgAspectRatio={items[0].aspectRatio}
        lastImgAspectRatio={items[items.length - 1].aspectRatio}
      >
        {myItems?.map((item, i) => {
          return (
            <ImageWrapper key={`image--${i}`} aspectRatio={item.aspectRatio}>
              <Image src={item.src} srcSet={item.srcSet} alt={item.src} />
            </ImageWrapper>
          );
        })}
      </ContainerDiv>
    </Wrapper>
  );
};

export default Gallery;
