import React from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import logo from "@assets/studioupdate.svg";

const Container = styled.div`
  padding: 90px 16px;

  img {
    max-width: 80vw;
    margin: 0 auto;
    display: block;
  } //test

  @media (min-width: 768px) {
    padding: 180px 16px;

    img {
      max-width: 60vw;
    }
  }

  @media (min-width: 992px) {
    img {
      max-width: 40vw;
    }
  }
`;

const Footer = () => {
  return (
    <Container>
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 90px 0px;

          img {
            max-width: 80vw;
          } //test

          @media (min-width: 768px) {
            padding: 180px 0px;

            img {
              max-width: 60vw;
            }
          }

          @media (min-width: 992px) {
            img {
              max-width: 40vw;
            }
          }
        `}
      >
        <img src={logo} />
      </div>
    </Container>
  );
};

export default Footer;
