import React, { useContext, useEffect, useRef } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { PassProtectionContext } from "@providers/PassProtectionProvider";
import StudioLogo from "@components/StudioLogo";

const PassInput = styled.input`
  box-shadow: none !important;
  height: 36px;
  border-radius: 18px;
  outline: none !important;
  border: 2px solid ${props => (props.hasError ? "#e74f4e" : "white")};
  background: transparent;
  color: white;
  width: 240px;
  font-size: 18px;
  padding: 0px 36px;
  text-align: center;
  text-transform: uppercase;
  font-family: "moncler-gothic";

  @media (min-width: 768px) {
    font-size: 24px;
    padding: 0px 42px;
    height: 42px;
    border-radius: 21px;
    width: 300px;
  }
`;

const PassButton = styled.button`
  box-shadow: none !important;
  height: 36px;
  border-radius: 18px;
  outline: none !important;
  text-transform: uppercase;
  background: white;
  color: black;
  border: 2px solid ${props => (props.hasError ? "#e74f4e" : "white")};
  cursor: pointer;
  width: 240px;
  font-size: 18px;
  padding: 0px 36px;
  text-align: center;
  font-family: "moncler-gothic";
  transition: 0.35s all;

  &:hover {
    background: black;
    color: white;
  }

  @media (min-width: 768px) {
    font-size: 24px;
    padding: 0px 42px;
    height: 42px;
    border-radius: 21px;
    width: 300px;
  }
`;

const PassForm = styled.form`
  position: relative;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
`;

const ErrorMessage = styled.span`
  color: #e74f4e;
  position: absolute;
  left: 0;
  font-size: 14px;
  font-family: "moncler-gothic";
  top: -20px;
  text-align: center;
  line-height: 1em;
  width: 100%;
`;

const PasswordScreen = () => {
  const passRef = useRef(null);
  const contentRef = useRef(null);
  const { tryPassword, passwordIsTrue } = useContext(PassProtectionContext);

  const submitPassword = e => {
    e.preventDefault();
    tryPassword(passRef.current.value);
  };

  useEffect(() => {
    contentRef.current.classList.add("show");
  }, []);

  return (
    <div
      css={css`
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100%;
        background-color: black;
        z-index: 10;
        transition: 0.25s all;
        opacity: ${passwordIsTrue ? 0 : 1};
        pointer-events: ${passwordIsTrue ? "none" : "all"};
        display: flex;
        align-items: center;
        justify-content: center;
      `}
    >
      <div
        ref={contentRef}
        css={css`
          opacity: 0;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: 0.95s all ease-in-out;
          transition-delay: 0.65s;

          &.show {
            opacity: 1;
          }
        `}
      >
        <StudioLogo>
          <PassForm onSubmit={submitPassword}>
            <div
              css={css`
                position: relative;
                margin-bottom: 16px;
              `}
            >
              <PassInput
                ref={passRef}
                hasError={passwordIsTrue === false}
                type="password"
                placeholder="password"
              />
              {passwordIsTrue === false && (
                <ErrorMessage>The password you entered is wrong.</ErrorMessage>
              )}
            </div>
            <PassButton onClick={submitPassword}>login</PassButton>
          </PassForm>
        </StudioLogo>
      </div>
    </div>
  );
};

export default PasswordScreen;
