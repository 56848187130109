import React from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";

import withAnimation, { fadeIn } from "@effects/withAnimation";

const ImageBase = styled.img``;
const Image = withAnimation(fadeIn)(ImageBase);

const Images = ({ items }) => {
  return (
    <div
      css={css`
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
      `}
    >
      {items &&
        items.filter((item) => !!item.fluid).map((item, index) => (
          <div
            key={`item--${index}`}
            css={css`
              display: flex;
              align-items: center;
              justify-content: center;
            `}
          >
            <Image srcSet={item.fluid.srcSet} src={item.fluid.src} />
          </div>
        ))}
    </div>
  );
};

export default Images;
