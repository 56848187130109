import React from "react";
import styled from "@emotion/styled";
// import logo from "@assets/logo.svg";
import { css } from "@emotion/react";
import withAnimation, { fadeIn } from "@effects/withAnimation";

const CM_WIDTH_MOBILE = 36;
const CM_WIDTH_DESKTOP = 90;

const CropMark = styled.div`
  position: relative;
  height: ${CM_WIDTH_MOBILE}px;
  width: ${CM_WIDTH_MOBILE}px;

  @media (min-width: 768px) {
    height: ${CM_WIDTH_DESKTOP}px;
    width: ${CM_WIDTH_DESKTOP}px;
  }

  &:before,
  &:after {
    content: "";
    position: absolute;
    background: white;
  }

  &:before {
    height: 2px;
    width: ${CM_WIDTH_MOBILE - 8}px;
    bottom: 0;
    left: 0;

    @media (min-width: 768px) {
      width: ${CM_WIDTH_DESKTOP * (2 / 3)}px;
    }
  }

  &:after {
    width: 2px;
    height: ${CM_WIDTH_MOBILE - 8}px;
    top: 0;
    right: 0;

    @media (min-width: 768px) {
      height: ${CM_WIDTH_DESKTOP * (2 / 3)}px;
    }
  }
`;

const CropMarks = ({ children }) => {
  return (
    <TextContainer>
      <CropMark
        css={css`
          position: absolute;
          top: -${CM_WIDTH_MOBILE}px;
          left: -${CM_WIDTH_MOBILE}px;

          @media (min-width: 768px) {
            top: -${CM_WIDTH_DESKTOP}px;
            left: -${CM_WIDTH_DESKTOP}px;
          }
        `}
      />
      <CropMark
        css={css`
          position: absolute;
          transform: rotate(90deg);

          top: -${CM_WIDTH_MOBILE}px;
          right: -${CM_WIDTH_MOBILE}px;

          @media (min-width: 768px) {
            top: -${CM_WIDTH_DESKTOP}px;
            right: -${CM_WIDTH_DESKTOP}px;
          }
        `}
      />
      <CropMark
        css={css`
          position: absolute;
          transform: rotate(180deg);

          bottom: -${CM_WIDTH_MOBILE}px;
          right: -${CM_WIDTH_MOBILE}px;

          @media (min-width: 768px) {
            bottom: -${CM_WIDTH_DESKTOP}px;
            right: -${CM_WIDTH_DESKTOP}px;
          }
        `}
      />
      <CropMark
        css={css`
          position: absolute;
          transform: rotate(270deg);

          bottom: -${CM_WIDTH_MOBILE}px;
          left: -${CM_WIDTH_MOBILE}px;

          @media (min-width: 768px) {
            bottom: -${CM_WIDTH_DESKTOP}px;
            left: -${CM_WIDTH_DESKTOP}px;
          }
        `}
      />
      {children}
    </TextContainer>
  );
};

const TextContainer = styled.div`
  position: relative;
`;

const ParagraphBase = styled.p`
  margin: 0px;
  text-align: center;
  line-height: 1.35em;
  font-weight: normal;
  font-family: "whyte-ink-trap";
  font-size: 16px;
  letter-spacing: 0;
  margin-top: 30px;

  @media (min-width: 768px) {
    font-size: 19px;
    margin-top: 60px;
  }

  a {
    border-bottom: 1px solid white;

    &:hover {
      opacity: 0.75;
    }
  }
`;

const Paragraph = withAnimation(fadeIn)(ParagraphBase);

const HeadingBase = styled.h4`
  font-family: "whyte-ink-trap";
  font-size: 32px;
  font-weight: normal;
  line-height: 0.9;
  text-transform: uppercase;
  text-align: center;
  margin: 0px;

  @media (min-width: 768px) {
    font-size: 54px;
  }
`;

const Heading = withAnimation(fadeIn)(HeadingBase);

const Container = styled.div`
  padding: 120px ${CM_WIDTH_MOBILE + 6}px 120px;

  @media (min-width: 768px) {
    padding: 240px 108px 240px;
    max-width: 1000px;
    margin: 0 auto;
    display: block;
  }

  @media (min-width: 1200px) {
    padding: 240px ${CM_WIDTH_MOBILE}px 240px;
    max-width: 1000px;
    margin: 0 auto;
    display: block;
  }
`;

const Logo = styled.img`
  max-width: 120px;
  margin: 0px auto 16px;
  display: block;

  @media (min-width: 768px) {
    max-width: 160px;
  }
`;

const Copy = ({ content, id }) => {
  const renderContent = () =>
    content.map(item =>
      item.__typename === "DatoCmsHeadline" ? (
        <Heading
          key={item.text}
          dangerouslySetInnerHTML={{ __html: item.text }}
        />
      ) : (
        <Paragraph
          key={item.text}
          dangerouslySetInnerHTML={{ __html: item.text }}
        />
      )
    );

  // const renderLogo = () => <Logo src={logo} />;
  // //{id === "moncler-intro" && renderLogo()}
  return (
    <Container>
      <CropMarks>{renderContent()}</CropMarks>
    </Container>
  );
};

export default Copy;
