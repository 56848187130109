import React from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { setMargin } from "@utilities/styles";
import { Container } from "@components/Markup";

const Heading = styled.h4`
  font-family: "moncler-gothic";
  font-size: 40px;
  font-weight: normal;
  line-height: 0.9;
  text-transform: uppercase;
  text-align: center;
  margin: 0px;

  @media (min-width: 768px) {
    font-size: 54px;
  }
`;

const HighlightBlock = ({ text }) => (
  <div
    css={css`
      margin: 0 auto;
    `}
  >
    <Container size="md">
      <Heading dangerouslySetInnerHTML={{ __html: text }} />
    </Container>
  </div>
);

HighlightBlock.defaultProps = {
  text: `At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis
          praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias
          excepturi sint occaecati cupiditate non provident, similique sunt in culpa
          qui officia deserunt mollitia animi, id est laborum et dolorum fuga.`,
};

HighlightBlock.propTypes = {
  text: PropTypes.string,
};

export default HighlightBlock;

// пиши на ленко, говори с мотко
