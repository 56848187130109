import { createElement as h } from "react";
import styled from "@emotion/styled";

import PropTypes from "prop-types";
import { enableMarginAndPadding } from "@utilities/styles";

const TITLE_SIZES = {
  xs: {
    mobile: 14,
    tablet: 14,
    desktop: 14,
  },
  sm: {
    mobile: 16,
    tablet: 16,
    desktop: 16,
  },
  md: {
    mobile: 24,
    tablet: 24,
    desktop: 32,
  },
  lg: {
    mobile: 40,
    tablet: 48,
    desktop: 64,
  },
  xl: {
    mobile: 32,
    tablet: 48,
    desktop: 60,
  },
};

export const withDynamicTag = Component => {
  const bucket = Object.create(null);

  const DynamicTag = props => {
    const { tag } = props;

    if (bucket[tag] === undefined) {
      bucket[tag] = Component.withComponent(tag);
    }

    return h(bucket[tag], props);
  };

  const name = Component.displayName || Component.constructor.name;

  if (name) {
    DynamicTag.displayName = `DynamicTag(${name})`;
  }

  return DynamicTag;
};

/* The title supports individual properties */
/* tag, bold, size. additionally, you can add css */

export const TitleBase = styled.h1`
  font-weight: 400;
  line-height: 1.3em;
  margin: 0px;
  font-family: ${props =>
      props.condensed ? "univers-bold-cond" : "univers-bold"},
    sans-serif;
  text-transform: ${props => (props.uppercase ? "uppercase" : "none")};
  font-size: ${props => TITLE_SIZES[props.size || "md"].mobile}px;

  @media (min-width: 768px) {
    font-size: ${props => TITLE_SIZES[props.size || "md"].tablet}px;
  }

  @media (min-width: 1200px) {
    font-size: ${props => TITLE_SIZES[props.size || "md"].desktop}px;
  }

  ${props => enableMarginAndPadding(props)};
`;

export const Title = withDynamicTag(TitleBase);

Title.propTypes = {
  tag: PropTypes.string,
  bold: PropTypes.bool,
  size: PropTypes.string,
};

Title.defaultProps = {
  tag: "h1",
  bold: true,
};

/* The text component has the option for predefined styles */
/* Available current styles are 'caption' and 'label' */
/* It also supports individual properties */

const TextBase = styled.p`
  line-height: 1.2em;
  font-weight: normal;
  font-family: "whyte-ink-trap";
  font-size: 16px;
  letter-spacing: 0;

  @media (min-width: 768px) {
    font-size: 19px;
  }

  ${props => enableMarginAndPadding(props)};

  a {
    &:hover {
      opacity: 0.75;
    }
  }
`;

export const Text = withDynamicTag(TextBase);

Text.propTypes = {
  tag: PropTypes.string,
  size: PropTypes.string,
  bold: PropTypes.bool,
  uppercase: PropTypes.bool,
  textStyle: PropTypes.string,
};

Text.defaultProps = {
  tag: "p",
  size: "sm",
  bold: false,
  uppercase: false,
};

export const HeadingBase = styled.h1`
  font-family: "opensans-extrabold";
  text-transform: uppercase;
  font-size: 40px;
  letter-spacing: -0.24px;
  line-height: 1em;
  opacity: ${props => {
    return props.opacity ? "0.45" : "1";
  }};

  @media (min-width: 768px) {
    font-size: 64px;
  }

  ${props => enableMarginAndPadding(props)};
`;

export const Heading = withDynamicTag(HeadingBase);
