import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  height: 100vh;
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
`;

export const ContainerDiv = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row !important;
  flex-wrap: nowrap;
  height: 100%;
  align-items: center;
  padding-top: 50px;
  @media (min-width: 768px) {
    padding: ${props =>
      `0
      calc((100vw - (${90 * props.lastImgAspectRatio}vh -
        ${90 * props.lastImgAspectRatio}px)) / 2)
      0
      calc((100vw - (${90 * props.firstImgAspectRatio}vh -
        ${90 * props.firstImgAspectRatio}px)) / 2)`};
    padding-top: 90px;
  }
`;

export const ImageWrapper = styled.div`
  padding: 0px 8px;
  width: ${props =>
    `calc(${90 * props.aspectRatio}vh - ${90 * props.aspectRatio}px);`};
`;

export const Image = styled.img`
  max-width: 100%;
  height: calc(90vh - 90px);
`;
