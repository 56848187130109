import React from "react";
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { Text } from "@components/Typography";
import { Container } from "@components/Markup";

const Paragraph = ({ text }) => {
  return (
    <Container
      size="md"
      css={css`
        margin: 0 auto;
        a {
          border-bottom: 1px solid white;
          color: white !important;
        }

        p {
          white-space: pre-wrap;
          margin: 0px;
          text-align: center;
        }
      `}
    >
      <Text tag="p" dangerouslySetInnerHTML={{ __html: text }} />
    </Container>
  );
};

Paragraph.defaultProps = {
  text: `At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis
          praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias
          excepturi sint occaecati cupiditate non provident, similique sunt in culpa
          qui officia deserunt mollitia animi, id est laborum et dolorum fuga.`,
};

Paragraph.propTypes = {
  text: PropTypes.string,
};

export default Paragraph;
