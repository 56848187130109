import React from "react";
import styled from "@emotion/styled";
import studio from "@assets/studioupdate.svg";

const Container = styled.div`
  width: 100%;
  padding: 0px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 768px) {
    max-width: 60vw;
  }

  @media (min-width: 992px) {
    max-width: 40vw;
  }

  img {
    margin-bottom: 30px;

    @media (min-width: 768px) {
      margin-bottom: 60px;
    }
  }
`;

const StudioLogo = ({ children }) => {
  return (
    <Container>
      <img src={studio} />
      {children}
    </Container>
  );
};

export default StudioLogo;
