import Paragraph from "@components/Paragraph";
import Highlight from "@components/Highlight";
import Hero from "@components/Hero";
import Video from "@components/Video";
import Images from "@components/Images";
import ImageText from "@components/ImageText";
import Gallery from "@components/Gallery";
import Quote from "@components/Quote";
import CTA from "@components/CTA";

// custom
import Copy from "@components/MonclerCopy";

import SectionFeature from "@components/SectionFeature";
import SectionIntro from "@components/SectionIntro";
import SectionFooter from "@components/SectionFooter";

export const SECTIONS = { SectionIntro, SectionFeature, SectionFooter };
export const ELEMENTS = {
  Paragraph,
  Highlight,
  Hero,
  Video,
  Images,
  ImageText,
  Gallery,
  Quote,
  CTA,
  Copy,
};
