import React, { useRef, useEffect, useState } from "react";
import { css } from "@emotion/react";
import { VolumeMuteOutline, VolumeHighOutline } from "react-ionicons";
import Player from "@vimeo/player";
import logo from "@assets/studioupdate.svg";

const PROPORTION = 1.78; /* 9:16 */ //
const PROPORTION_MOBILE = 638 / 510;

const mobileId = "731724957";
const desktopId = "731749923";

const Hero = ({ vimeoId }) => {
  const videoContainerRef = useRef(null);
  const playerRef = useRef(null);
  const [mute, setMute] = useState(true);

  const initVideo = () => {
    if (playerRef.current) {
      playerRef.current.destroy();
    }

    const windowProportion = window.innerWidth / window.innerHeight;
    const vp = window.innerWidth >= 1200 ? PROPORTION : PROPORTION_MOBILE;

    let _w, _h;

    if (windowProportion > vp) {
      _w = window.innerWidth;
      _h = _w / vp;
    } else {
      _h = window.innerHeight;
      _w = _h * vp;
    }

    const id = window.innerWidth >= 1200 ? desktopId : mobileId;

    playerRef.current = new Player(videoContainerRef.current, {
      id: id,
      controls: false,
      autoplay: true,
      loop: true,
      width: _w,
      height: _h,
      muted: true,
      autopause: false,
    });

    playerRef.current.ready().then(() => {
      const iframe = videoContainerRef.current.querySelector("iframe");
      iframe.setAttribute("allow", "autoplay; fullscreen; picture-in-picture");
    });
  };

  // const initVideoDebounced = debounce(initVideo, 200);

  const toggleMute = () => {
    setMute(!mute);
  };

  const renderIcon = () => {
    //
    const C = mute ? VolumeMuteOutline : VolumeHighOutline;

    return <C width="30px" color="white" />;
  };

  useEffect(() => {
    playerRef.current?.setMuted(mute);
  }, [mute]);

  useEffect(() => {
    initVideo();
  }, []);

  return (
    <div
      css={css`
        width: 100%;
        background: black;
        position: relative;
        pointer-events: none !important;
      `}
    >
      <div
        css={css`
          overflow: hidden;
          position: relative;
        `}
      >
        <div
          ref={videoContainerRef}
          css={css`
            height: 100vh;
            width: 100%;
            position: relative;

            iframe {
              pointer-events: none;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate3d(-50%, -50%, 0);
              pointer-events: none !important;

              body * {
                pointer-events: none;
              }
            }
          `}
        />
        <div
          css={css`
            position: absolute;
            z-index: 2;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            pointer-events: all;
          `}
        >
          <button
            onClick={toggleMute}
            css={css`
              background-color: transparent;
              border: none;
              outline: none;
              padding: 16px;
              cursor: pointer;
              transition: 0.25s all;
              position: absolute;
              bottom: 16px;
              right: 16px;

              &:hover {
                opacity: 0.65;
              }
            `}
          >
            {renderIcon()}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Hero;
