import React, { useEffect, useRef } from "react";
import { css } from "@emotion/react";
import VisibilitySensor from "react-visibility-sensor";
import gsap from "gsap";

const DURATION = 0.75;

/* usage
import withAnimation, { fadeIn } from "@effects/withAnimation";
const TextBlock = withAnimation(fadeIn)(TextBlockBase);
*/

export const fadeIn = {
  from: {
    opacity: 0,
  },
  to: {
    opacity: 1,
  },
  duration: DURATION,
};

export const fadeInDelay = {
  from: {
    opacity: 0,
  },
  to: {
    opacity: 1,
  },
  delay: 0.25,
  duration: DURATION,
};

export const fadeInUp = {
  from: {
    opacity: 0,
    y: 20,
  },
  to: {
    opacity: 1,
    y: 0,
  },
  duration: DURATION,
};

const defaultAnimation = fadeIn;

const withAnimation = animation => WrappedComponent => {
  const { from, to, duration } = animation || defaultAnimation;
  const WithWrapper = props => {
    const wrapperRef = useRef();
    let hasSetInitialValues = useRef(null);
    let hasAnimated = false; // do only once

    useEffect(() => {
      if (!hasSetInitialValues.current && wrapperRef.current) {
        hasSetInitialValues.current = true;
        gsap.set(wrapperRef.current, {
          ...from,
        });
      }
    }, []);

    const onPageEnter = isVisible => {
      if (!hasAnimated && isVisible) {
        gsap.to(wrapperRef.current, {
          ...to,
          duration: duration,
          ease: "power3.inOut",
        });
      }
    };

    return (
      <VisibilitySensor
        partialVisibility={true}
        minTopValue={100}
        onChange={onPageEnter}
      >
        <div
          ref={wrapperRef}
          css={css`
            position: relative;
          `}
        >
          <WrappedComponent {...props} />
        </div>
      </VisibilitySensor>
    );
  };

  WithWrapper.displayName = `WithWrapper(${WrappedComponent.name})`;
  return WithWrapper;
};

export default withAnimation;
